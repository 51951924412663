import Glide, { Controls, Autoplay  } from '@glidejs/glide/dist/glide.modular.esm'

window.addEventListener('scroll', function () {
  var body = document.body;
  if (window.scrollY > 420) {
    body.classList.add('scrolled');
  } else {
    body.classList.remove('scrolled');
  }
});

// Navbar Button toggle
const menuToggle = document.getElementById('menu-toggle');
const navbar = document.getElementById('nav');
const navLinks = document.querySelectorAll('.nav-link');
const hoverArea = document.getElementById('hover-area');

menuToggle.addEventListener('click', () => {
  navbar.classList.toggle('open');
});
navLinks.forEach(link => {
  link.addEventListener('click', () => {
    navbar.classList.remove('open');
  });
});

hoverArea.addEventListener('mouseenter', () => {
  navbar.classList.add('open');
});

hoverArea.addEventListener('mouseleave', () => {
  navbar.classList.remove('open');
});

// glide.js
const header = document.querySelector('.headerslider');
if (header) {
  var headerslider = new Glide('.headerslider', {
    type: 'carousel',
    autoplay: 30000,
    startAt: 0,
    perView: 1,
    gap: 0,
    animationTimingFunc: 'ease-in-out',
    animationDuration: 800,
  });
  //headerslider.mount();
  headerslider.mount({ Controls, Autoplay  })
}
